import React from "react";

function Contact() {
    return (
        <div className="contact">
            <div className="container">
                <div className="row align-items-center my-5">
                    <div className="col-lg-12">
                        <h1>Contact page</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
