import React, {useEffect, useState} from "react";
import { useParams } from "react-router";
import axios from 'axios';

function Oglas() {

    let { oglasSlug } = useParams();
    const [ad, setAd] = useState();

    const fetchAd = () => {
        axios.get('https://netoglasi.net/getapi.php?type=single-ad').then(res => {
            console.log(res);
            setAd(res.data);
        })
    }

    useEffect(() => {
        // Trigger the API Call
        fetchAd();
    }, []);


    return (
        <div className="about">
            <div className="container">
                <div className="row align-items-center my-5">

                        {oglasSlug}
                        { ad &&
                        <div className="col-lg-12">
                            <h1>{ad.title}</h1>
                            <p>{ad.description}</p>
                            <img src={`https://netoglasi.rs/${ad.images[0].detailPath}`}/>
                        </div>
                        }
                </div>
            </div>
        </div>
    );
}

export default Oglas;
