import React, {useState, useEffect} from "react"
import axios from 'axios'
import { Link } from "react-router-dom";

function Home() {
    const [categories, setCategories] = useState([]);
    const [ads, setAds] = useState([]);

    const fetchData = () => {
        const getCats = axios.get('https://netoglasi.net/getapi.php?type=categories');
        const getAds = axios.get('https://netoglasi.net/getapi.php?type=ads');

        axios.all([getCats,getAds]).then(
            axios.spread((...allData) => {
                console.log(allData);
                setCategories(allData[0].data);
                setAds(allData[1].data.items);
            })
        )
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="home">
            <div class="container">
                <div class="row my-5">
                    <div class="col-lg-2">
                        <ul className="nav flex-column">
                            {
                                categories.map(category => (
                                    <li className="nav-item">
                                        <Link to={`/kategorija/${category.slug}`} activeClassName="active">{category.title}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="col-lg-10">
                        <div className="row">
                            {
                                ads.map(ad => (
                                    <div className="col-lg-12">
                                        <Link to={`/oglas/${ad.slug}`} activeClassName="active"><b>{ad.title}</b></Link>
                                        <br/>
                                        {ad.description}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
